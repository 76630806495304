import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import SEO from "../components/seo";
import { Formik } from "formik";
import * as Yup from "yup";

const axios = require("axios").default;

function ContatoPage() {
    return (
        <>
            <SEO title="Contato" />
            <section className="py-8 px-12 lg:py-16 lg:px-6">
                <div className="container mx-auto  text-azul_base ">
                    <h1 className="leading-none text-2xl font-bold mb-4 lg:text-4xl lg:mb-12">Contato</h1>
                    <div className="flex flex-col lg:flex-row lg:justify-start">
                        <div className="lg:w-1/2">
                            <p className="text-base text-preto leading-none mb-6 lg:text-xl lg:leading-tight">
                                Para tirar dúvidas ou sugestões sobre nossos serviços, envie um e-mail através do formulário ao lado
                                ou entre em contato pelo telefone (11)5585-9945
                            </p>
                            <p className="text-azul_base text-sm mb-6 lg:text-lg">
                                <strong>
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </strong> <a href="https://encurtador.com.br/mIM47">(11)5585-0112</a>
                            </p>
                            <p className="text-azul_base text-sm mb-6 lg:text-lg">
                                <strong>Endereço</strong>: Av. Jabaquara, 1336 - Mirandópolis, São Paulo - SP, 04046200
                            </p>
                            <p className="text-azul_base text-sm lg:text-lg">
                                <strong>Funcionamento</strong>: Seg-Sex 9:00-17:00 Sábados 9:00-12:00
                            </p>
                        </div>
                        <div className="text-base mt-4 lg:text-lg lg:w-2/3 lg:mt-0 lg:ml-12 lg:flex lg:flex-col">
                            <Formik
                                initialValues={{ email: "", assunto: "", mensagem: "", accept: false }}
                                onSubmit={(values, actions) => {
                                    return axios
                                        .post("/send.php", {
                                            email: values.email,
                                            assunto: values.assunto,
                                            mensagem: values.mensagem,
                                        })
                                        .then(res => {
                                            console.log(res);
                                            actions.resetForm();
                                            document.getElementById("success").innerText =
                                                "Mensagem enviada com sucesso";
                                            setTimeout(() => {
                                                document.getElementById("success").innerText = "";
                                            }, 5000);
                                        })
                                        .catch(err => {
                                            console.log(err);
                                            actions.setFieldError(
                                                "mensagem",
                                                "Ocorreu um erro ao enviar sua mensagem, por favor tente novamente"
                                            );
                                        });
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().email("Este não é um e-mail válido").required("Por favor insira seu e-mail"),
                                    assunto: Yup.string().required("O campo assunto é obrigatório"),
                                    mensagem: Yup.string().required("O campo mensagem é obrigatório"),
                                    accept: Yup.boolean().oneOf([true], 'Leia e Aceite as Políticas de Privacidade antes de enviar'),
                                })}>
                                {({
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    handleReset,
                                }) => {
                                    return (
                                        <form className="flex flex-col" onSubmit={handleSubmit}>
                                            <label className="text-preto font-bold mb-1" htmlFor="email">
                                                Email
                                            </label>
                                            <input
                                                id="email"
                                                type="text"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="p-3 bg-cinza_claro rounded mb-4"
                                            />
                                            {errors.email && touched.email && (
                                                <div className="text-vermelho -mt-3 mb-4">{errors.email}</div>
                                            )}
                                            <label className="text-preto font-bold mb-1" htmlFor="assunto">
                                                Assunto
                                            </label>
                                            <input
                                                id="assunto"
                                                type="text"
                                                value={values.assunto}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="p-3 bg-cinza_claro rounded mb-4"
                                            />
                                            {errors.assunto && touched.assunto && (
                                                <div className="text-vermelho -mt-3 mb-4">{errors.assunto}</div>
                                            )}
                                            <label className="text-preto font-bold mb-1" htmlFor="mensagem">
                                                Mensagem
                                            </label>
                                            <textarea
                                                id="mensagem"
                                                type="text"
                                                value={values.mensagem}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                rows="8"
                                                className="p-3 bg-cinza_claro rounded mb-4 resize-none"
                                            />
                                            {errors.mensagem && touched.mensagem && (
                                                <div className="text-vermelho -mt-3 mb-4">{errors.mensagem}</div>
                                            )}
                                            <label className="text-preto">
                                                <input
                                                    id="accept"
                                                    type="checkbox"
                                                    checked={values.accept}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="color-black"
                                                />
                                                &nbsp;&nbsp;Estou de acordo com o <a href="/terms" className="font-bold" target="_blank">Termo de Uso de Dados</a>
                                            </label>
                                            {errors.accept && touched.accept && (
                                                <div className="text-vermelho mt-2 mb-4">{errors.accept}</div>
                                            )}
                                            <div className="text-green-700" id="success"></div>
                                            <div className="flex justify-end mt-4">
                                                <button
                                                    className={`px-6 py-2 text-white cursor-pointer ${
                                                        isSubmitting ? "bg-gray-800" : "bg-azul_claro"
                                                    } rounded`}
                                                    type="submit"
                                                    disabled={isSubmitting}>
                                                    Enviar
                                                </button>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContatoPage;
